/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 12번째 로그에서는 Seocho.rb, GitHub 새로운 가격 정책, AWS Fargate 가격 인하, AWS VPC 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/183"
  }, "Seocho.rb 첫 번째 모임: 서버리스 루비 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/?searchCoord=00e728ae343225675cf12d9c488da64f32c93bd852f4ca93fe2df597e5da55da&query=7ISc7LSI6rWs&tab=1&lng=d562f59375d38afbe483363b9a58f82a&mapMode=0&mpx=ac44d5ea6cf1ecdd5426a20f66c6baf3a049d379d8d797b181b0d5af20daf2150c70ded690f42a57fcce31e6b0bf2d067b7d3a160fa89e4fa8160c9b7f94dc97&lat=6979fe4170133b159d84961dd5d4f090&dlevel=9&enc=b64&menu=location"
  }, "네이버 지도 - 서초구")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.com/AVerMedia-Live-Gamer-Ultra-GC553/dp/B07DHV47HF/ref=sr_1_1?ie=UTF8&qid=1547042718&sr=8-1&keywords=GC553+Live+Gamer+ULTRA"
  }, "Amazon.com: AVerMedia Live Gamer Ultra (GC553)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/High-bandwidth_Digital_Content_Protection"
  }, "HDCP: High-bandwidth Digital Content Protection - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://manuals.playstation.net/document/en/ps4/settings/hdcp.html"
  }, "Enable HDCP | PlayStation®4 User's Guide")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://confreaks.tv/"
  }, "Confreaks TV")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCKD03IyQD6XrhBGzVH9qF7A"
  }, "RORLab KR - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/guide/quicktime-player/record-your-screen-qtp97b08e666/mac"
  }, "Mac용 QuickTime Player에서 화면 기록하기 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://gametoc.hankyung.com/news/articleView.html?idxno=50354"
  }, "대도서관, 유튜브 떠난다…트위치에 새둥지 - 게임톡")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/"
  }, "Festa! 이벤트를 만나는 가장 쉬운 방법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/105"
  }, "하시코프 한국 사용자모임의 두 번째 서울 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/21876732"
  }, "스타트업 '온오프믹스' 대표·부대표, 강간·추행 혐의 피소")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/90"
  }, "FEConf 2018 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://meetgo.kr/#/userEventDetail/feconf2018"
  }, "Meetgo :: 오프라인 모임 플랫폼 밋고")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.meetup.com/ko-KR/"
  }, "함께의 가치 | Meetup")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techplay.jp/"
  }, "TECH PLAY［テックプレイ］ - IT勉強会・セミナーなどのイベント情報検索サービス")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://goree.io/events/3"
  }, "사이를 잇는 Goree - Planet Hackathon 2018 by GDG x 9XD ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.github.com/2019-01-07-new-year-new-github/"
  }, "New year, new GitHub: Announcing unlimited free private repos and unified Enterprise offering | The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--github-announcing-unlimited-free-private-repository"
  }, "깃헙(GitHub) 새로운 가격 정책 및 엔터프라이즈 플랜 발표 - 무료 플랜도 비공개 저장소를 무제한 사용 가능 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/product/continuous-integration/"
  }, "GitLab Continuous Integration & Delivery | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devops.com/gitlab-joins-unicorn-club-with-100m-raise/"
  }, "GitLab Joins Unicorn Club With $100m Raise - DevOps.com")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitbucket.org/product/pricing"
  }, "Pricing | Bitbucket")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://about.gitlab.com/company/culture/all-remote/"
  }, "All Remote | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitbucket.org/site/master/issues/4307/feature-request-contributor-statistics-bb"
  }, "site / master / issues / #4307 - Feature Request: Contributor Statistics (BB-4787) — Bitbucket")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.gitlab.com/ee/user/project/import/github.html"
  }, "Import your project from GitHub to GitLab | GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/github/VisualStudio"
  }, "github/VisualStudio: GitHub Extension for Visual Studio")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2019/01/announcing-aws-fargate-price-reduction-by-up-to-50-/"
  }, "Announcing AWS Fargate Price Reduction by Up To 50%")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--fargate-significant-price-cuts"
  }, "AWS 파게이트(Fargate) 가격 30%-50% 인하 발표 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/systems-manager/latest/userguide/ssm-agent.html"
  }, "Installing and Configuring SSM Agent - AWS Systems Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/systems-manager/latest/userguide/systems-manager-paramstore.html"
  }, "AWS Systems Manager Parameter Store - AWS Systems Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/11/aws-launches-secrets-support-for-amazon-elastic-container-servic/"
  }, "AWS Launches Secrets Support for Amazon Elastic Container Service")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/12/aws-fargate-platform-version-1-3-adds-secrets-support/"
  }, "AWS Fargate 플랫폼 버전 1.3, 보안 지원 추가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/kms/"
  }, "Key Management Service – Amazon Web Services(AWS)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/segmentio/chamber"
  }, "segmentio/chamber: CLI for managing secrets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.vaultproject.io/"
  }, "Vault by HashiCorp")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.2dal.com/2018/12/31/nat-gateway-to-nat-instance/"
  }, "AWS NAT Gateway에서 NAT instance로 전환하기 – asbubam's blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/vpc/latest/userguide/vpc-nat-gateway.html"
  }, "NAT Gateways - Amazon Virtual Private Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/new-managed-nat-network-address-translation-gateway-for-aws/"
  }, "AWS 신규 NAT 게이트웨이 서비스 출시! | Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.digitalocean.com/"
  }, "DigitalOcean - Cloud Computing, Simplicity at Scale")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/jp/lightsail/"
  }, "Amazon Lightsail")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/vpc/?nc1=h_ls"
  }, "VPC Cloud VPS호스팅 | 가상 호스팅 | Amazon Web Services")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://joequery.me/code/ipv4-subnetmasks-cidr/"
  }, "Introduction to IP addresses, subnet masks, and CIDR notation | JoeQuery")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
